import isNumber from "is-number";
import { Config, ItemListingInput, Listing } from "./__generated__/graphql";
import httpService from "./httpService";
import moment from "moment";
import Cookies from "js-cookie";

export const isElectron = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf(" electron/") !== -1;
};

// Crazy JS modulo bug https://web.archive.org/web/20090717035140if_/javascript.about.com/od/problemsolving/a/modulobug.htm
export const modulo = (n: number, m: number) => ((n % m) + m) % m;

export function uploadToS3(signedUrl: string, file: File, options: any) {
  return httpService.put(signedUrl, file, options);
}

export const replaceAll = (string: string, search: string, replacement: string) => {
  if (!string || !search || replacement === undefined) return string;
  return string.replace(new RegExp(search, "g"), replacement);
};

export const findUrlForMedia = (media: any, format: any) => {
  if (!media || !media.formatArray) return null;
  else if (!format) return media.url;

  const foundFormat = media?.formatArray?.find((f: any) => f.name === format);
  return foundFormat?.url || media.url;
};

export const sanitizeListingData = (listing: any) => {
  if (isNumber(listing.prices.cost)) listing.prices.cost = parseFloat(listing.prices.cost);
  else delete listing.prices.cost;

  if (isNumber(listing.prices.compare)) listing.prices.compare = parseFloat(listing.prices.compare);
  else delete listing.prices.compare;

  if (isNumber(listing.prices.sale)) listing.prices.sale = parseFloat(listing.prices.sale);
  else delete listing.prices.sale;

  if (isNumber(listing.prices.beforeTaxes)) listing.prices.beforeTaxes = parseFloat(listing.prices.beforeTaxes);
  else delete listing.prices.beforeTaxes;

  if (isNumber(listing.stock.quantity)) listing.stock.quantity = parseInt(listing.stock.quantity);
  else delete listing.stock.quantity;

  return listing;
};

export const getConfigProperty = (config: Config, module: string, property: string) => {
  const m = config.modules.find((m: any) => m.id === module);
  if (m && m.data) return m.data[property];
  return null;
};

export const getPlaceholderImage = (config: Config) => {
  return getConfigProperty(config, "designs", "missingImageUri") || "/missing-primary.png";
};

export const removeTypename = (arr: any) => {
  if (!arr || !Array.isArray(arr)) return;
  return arr.map(obj => {
    delete obj.__typename;
    if (obj.alternative) delete obj.alternative.__typename;
    return obj;
  });
};

export const removeTypenameAndRef = (arr: any) => {
  if (!arr || !Array.isArray(arr)) return;
  return arr.map(obj => {
    delete obj.__typename;
    delete obj._id;
    return obj;
  });
};

export const removeTypenameFromObject = (obj: any) => {
  if (!obj) return;
  delete obj.__typename;
  return obj;
};
export const removeTypenameAndRefFromObject = (obj: any) => {
  if (!obj) return;
  delete obj.__typename;
  delete obj._id;
  return obj;
};

export const removeTypenameAndIds = (arr: any) => {
  if (!arr || !Array.isArray(arr)) return;
  return arr.map(obj => {
    delete obj.id;
    delete obj._id;
    delete obj.__typename;
    if (obj.alternative) {
      delete obj.alternative.id;
      delete obj.alternative._id;
      delete obj.alternative.__typename;
    }
    return obj;
  });
};

export const convertListingForGraphQL = (listing: Listing): ItemListingInput => ({
  status: listing.status,
  stock: { quantity: listing.stock.quantity },
  prices: { sale: listing.prices.sale },
  available: listing.available || moment().format(),
  secondHand: listing.secondHand,
  preOrder: listing.preOrder,
  options: listing.options.map(o => ({ value: o.value, name: o.name }))
});

export const getFormattedPrice = ({ value, config, currency }: { value: number; config?: Config; currency?: string }) => {
  return new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: currency || config?.plan.currency,
    maximumFractionDigits: 0
  }).format(value || 0);
};

export const isNearInventoryLimit = ({ config }: { config: Config }) => {
  return (config.statistics.inventory?.count || 1) / config.plan.features.inventory.limit > 0.75;
};

const isDevelopment = process.env.VITE_BUILD_ENV !== "production";
const jwtKey = isDevelopment ? "jwt-staging" : "jwt";
const isElectronApp = isElectron();

export const getSessionJWTForDomain = (): string | null => {
  return isElectronApp ? localStorage.getItem(jwtKey) : Cookies.get(jwtKey) || null;
};

export const writeSessionJWTForDomain = (jwt: string) => {
  if (isElectronApp) localStorage.setItem(jwtKey, jwt);
  else Cookies.set(jwtKey, jwt, isDevelopment ? {} : { domain: ".common-ground.io" });
};

export const removeSessionJWTForDomain = () => {
  if (isElectronApp) localStorage.removeItem(jwtKey);
  else Cookies.remove(jwtKey, isDevelopment ? {} : { domain: ".common-ground.io" });
};

export const swapElement = (array: any, indexA: number, indexB: number) => {
  var tmp = array[indexA];
  array[indexA] = array[indexB];
  array[indexB] = tmp;
};
